import React from 'react';
import Reg from './Reg';
import './Reg.css';
import Header3 from '../../Headers/Header3';
import Footer from '../../Footer';

const RegForm = () => {
  return (
    <div className="rsvp-div1">
    <Header3/>
      <Reg/>
    <Footer/>
    </div>
  );
};

export default RegForm;