import React, { useState } from 'react';
import './Reg.css';
import Header3 from '../../Headers/Header3';

const Reg = () => {
  const [formData, setFormData] = useState({
    name: '',
    school: '',
    contactNumber: '',
    email: '',
    age: '',
    onsiteAttendance: 'no',
    attendanceDate: '',
    esportsTitles: [],
    othersSpecify: '',
    agreeToTerms: 'no',
  });

  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      const updatedTitles = checked
        ? [...formData.esportsTitles, value]
        : formData.esportsTitles.filter((title) => title !== value);
      setFormData({ ...formData, esportsTitles: updatedTitles });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.agreeToTerms === 'no') {
      alert('You must agree to the Data Privacy Act Disclaimer to proceed.');
      return;
    }
    try {
      const response = await fetch('https://darkleaguestudios.com/submit_rsvp.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (result.error) {
        setError(result.error);
      } else {
        setError('');
        alert(result.message);
        // Reset form after successful submission
        setFormData({
          name: '',
          school: '',
          contactNumber: '',
          email: '',
          age: '',
          onsiteAttendance: 'no',
          attendanceDate: '',
          esportsTitles: [],
          othersSpecify: '',
          agreeToTerms: 'no',
        });
      }
    } catch (error) {
      console.error('Error submitting RSVP:', error);
    }
  };

  return (
    <div>
    <Header3/>  
    <img src="\rsvp\banner 2.png" alt="Uclash Logo" className="ee-img1" />
    <div className="rsvp-form">
    <img src="\rsvp\EETNC logo.png" alt="Uclash Logo" className="tnc-img-logo" />
      <h2 className="heder">RSVP Form</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        {/* Data Privacy Act Disclaimer */}
        <div className="disclaimer-section">
          <p className="rsvp-h1">
            <strong>1. Data Privacy Act Disclaimer:</strong> Click YES if you agree
          </p>
          <p className="act">
          Republic Act 10173 - Data Privacy Act of 2012
          ESTUDYANTE ESPORTS ENSURES THAT ALL DATA PROVIDED WILL BE SOLELY USED FOR REGISTRATION AND MARKETING PURPOSES ONLY. 
          IT SHALL ONLY BE VISIBLE TO THE ORGANIZERS AT ANY POINT OF THE EVENT AND IN NO WAY, SHAPE OR FORM BE USED TO COMPROMISE THE SAFETY AND SECURITY OF THE PARTICIPANT.
          </p>
          <label>
            <input
              type="radio"
              name="agreeToTerms"
              value="yes"
              checked={formData.agreeToTerms === 'yes'}
              onChange={handleChange}
            />{' '}
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="agreeToTerms"
              value="no"
              checked={formData.agreeToTerms === 'no'}
              onChange={handleChange}
            />{' '}
            No
          </label>
        </div>
        
        {/* Conditional Rendering for the Form */}
        {formData.agreeToTerms === 'yes' ? (
          <>
          <div className="profile-rsvp">
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              School:
              <input
                type="text"
                name="school"
                value={formData.school}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              Contact Number:
              <input
                type="tel"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                required
              />
            </label>

            <label>
              Email Address:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>


            <label>
              Age:
              <input
                type="number"
                name="age"
                value={formData.age}
                onChange={handleChange}
                required
                min="1"
                max="100"
              />
            </label>
            </div>

            <div className="check-1">
            <p className="rsvp-h1">
              <strong>2. Are you watching the National Grand Finals onsite?</strong>
            </p>
            <label>
              <input
                type="radio"
                name="onsiteAttendance"
                value="yes"
                checked={formData.onsiteAttendance === 'yes'}
                onChange={handleChange}
              />{' '}
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="onsiteAttendance"
                value="no"
                checked={formData.onsiteAttendance === 'no'}
                onChange={handleChange}
              />{' '}
              No
            </label>
            </div>

            <p className="rsvp-h2">
              <em>
                If you answer now, get a chance to win an early bird reward of
                Estudyante Esports Merchandise! For ONSITE CLAIMING ONLY!
              </em>
            </p>


            
            <p className="rsvp-h1">
              <strong>3. When are you going to attend?</strong>
            </p>
            <div className="venue-rsvp">
            <label>
              <input
                type="checkbox"
                name="attendanceDate"
                value="February 22, SATURDAY - Ayala Circuit Makati (MLBB, League of Legends, VALORANT)"
                onChange={handleChange}
              />{' '}
              February 22, SATURDAY - Ayala Circuit Makati (MLBB, League of Legends, VALORANT)
            </label>
            <label>
              <input
                type="checkbox"
                name="attendanceDate"
                value="February 23, SUNDAY - Dark League Studios HQ (TEKKEN 8)"
                onChange={handleChange}
              />{' '}
              February 23, SUNDAY - Dark League Studios HQ (TEKKEN 8)
            </label>
            </div>

            <p className="rsvp-h1">
              <strong>4. Do you play any of these esports titles?</strong>
            </p>
            {['VALORANT', 'League of Legends', 'MLBB', 'TEKKEN 8', 'Wild Rift', 'CODM'].map((title) => (
              <label key={title}>
                <input
                  type="checkbox"
                  name="esportsTitles"
                  value={title}
                  checked={formData.esportsTitles.includes(title)}
                  onChange={handleChange}
                />{' '}
                {title}
              </label>
            ))}
            <label>
              Others (please specify):
              <input
                type="text"
                name="othersSpecify"
                value={formData.othersSpecify}
                onChange={handleChange}
              />
            </label>
            <div>
              <button type="submit" class="fancy">SUBMIT</button>
            </div>
          </>
        ) : (
          <p className="disclaimer-warning">
            You must agree to the Data Privacy Act Disclaimer to proceed.
          </p>
        )}
      </form>
    </div>
    </div>
  );
};

export default Reg;